<template >
  <div class="containter">
    <!-- <div class="headArea">
      <img :src="backImg" style="width:100%;height:100%" />
    </div>
    <div class="titleFont">美丽城镇采集平台</div>
    <div style="width:100%;height:1rem;"></div>
    <div class="index-tab">
      <div v-for="item in tabList" class="item" :key="item.id" @click="gotoList(item.id)">
        <img src="./img/icon1.png" background-size="cover" style="width:1.333rem;height:1.333rem;" />
        <span style="font-size:0.32rem;margin-top:0.16rem;">{{item.name}}</span>
      </div>
    </div>-->
    <img src="./img/wholeBack.png" style="width:100%" />
    <div class="iconClick" @click="gotoList"></div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'login',

  data() {
    return {
      uername: '',
      password: '',
      backImg: require('./img/headPic.jpg'),
      tabList: [
        {
          id: '1',
          url: '/pages/service/service',
          name: '数据采集'
        }
      ]
    };
  },
  watch: {},
  created() {},
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    gotoList() {
      this.$router.push({ name: 'messageList' });
    }
  }
};
</script>
<style scoped>
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
}
.iconClick {
  position: absolute;
  width: 1rem;
  height: 1.5rem;
  top: 5.334rem;
  left: 2.32rem;
}
.headArea {
  width: 100%;
  height: 5rem;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.headPic {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
}
.titleFont {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.64rem;
  color: #409eff;
}
.inputArea {
  margin: 0.5rem 0.8rem;
}
.index-tab {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 2.43rem;
  width: 9.36rem;
  padding: 1rem 0.32rem 0 0.32rem;
  position: relative;
}
.item {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>



